import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AshGuidesNexus: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoing Nexus Overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_nexus.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Echoing Nexus</h1>
          <h2>
            An overview of the Echoing Nexus mode available in Ash Echoes.
          </h2>
          <p>
            Last updated: <strong>02/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Nexus is a roguelite mode core to progression in Ash Echoes. Nexus has
          a multitude of unique mechanics that can be confusing starting out.
          This guide will explain all you need to know about Nexus.
        </p>
        <SectionHeader title="Why do Nexus?" />
        <p>
          Nexus is a major part of progression in Ash Echoes. Engravings are
          earned by the lead character at the end of each run, granting you
          stats and passive effects for all content.
        </p>
        <p>
          In addition to Engravings, clearing Danger Zones for the first time
          will grant you a generous amount of X-Particles. Each path in Nexus
          also has reputation levels to progress through as you earn Engraving
          records on multiple characters, granting pulls for Traces.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_1.webp"
          alt="Guides"
        />
        <p>
          Each week, you can also earn Mirrorshards to level Traces and Coins
          for reaching depth milestones up to a cap of 80.
        </p>
        <p>
          Having high Engraving ratings across multiple characters will also
          provide growth rewards. These include XP, X-Particles, Resonance
          Clues, and increased DEF and TRM stats in all battles.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_2.webp"
          alt="Guides"
        />
        <SectionHeader title="Accessing Nexus?" />
        <p>
          Clicking the Nexus button in the lobby will take you to the Nexus
          menu. Here you can access{' '}
          <strong>Nexus: Simulation, Nexus: Surface</strong>, and any unlocked{' '}
          <strong>Echoing Nexus</strong>.
        </p>
        <p>
          <strong>Nexus: Simulation</strong> will consume a Blank Chip (obtained
          from daily activity) to instantly generate an Engraving with stats and
          skills based on character ascension and Memory Trace level.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_3.webp"
          alt="Guides"
        />
        <p>
          <strong>Nexus: Surface and Echoing Nexus</strong> require Spirit to
          enter. You will receive one Spirit on daily reset and can regenerate
          an additional one from daily activity. You can buy additional Spirits
          using X-Particles at a rate of 20 per Spirit. If you have 5 or more
          Spirit you will not regenerate more.
        </p>
        <ul>
          <li>
            <strong>Nexus: Surface</strong> involves taking a character on a
            short Nexus run through five map segments.
          </li>
          <ul>
            <li>
              Since Nexus: Surface runs are capped at Depth 5, Engravings
              generated will have low stats and few skills. Nexus: Surface is
              mostly used to provide a quick and easy way to finish
              daily/beginner missions.
            </li>
          </ul>
          <li>
            <strong>Echoing Nexus</strong> is the primary Nexus mode and
            involves going on a lengthier Nexus run through many map segments.
            This allows you to create an Engraving with many customizable stats
            and skills.
          </li>
        </ul>
        <StaticImage
          src="../../../images/ash/generic/nexus_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Completing an Echoing Nexus Run" />
        <h5>Beginning a Run</h5>
        <p>
          At the beginning of your run you will{' '}
          <strong>select a leader character</strong>. This character will be who
          you control throughout the run and will receive an Engraving upon
          Nexus completion. Each character has a set of initial Engraving stats
          and skills.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_5.webp"
          alt="Guides"
        />
        <p>
          After selecting a leader, you will{' '}
          <strong>choose five Memory Traces</strong> and an aiding Memory Trace
          from another player. These Traces will determine your Engraving stats
          and skills. The higher level your Traces, the more stats they will
          give.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_6.webp"
          alt="Guides"
        />
        <h5>Encounters</h5>
        <p>
          Groups of enemies will appear throughout your journey in the Nexus.
          You can only use your leader to take out these enemies. After
          defeating all enemies in an encounter, you will be given the choice to
          increase depth. As depth increases up to level 12, you will be granted
          higher stats and skill points. Past depth level 12, enemies will
          continue to ramp up in difficulty but no additional stats or skill
          points will be granted.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_7.webp"
          alt="Guides"
        />
        <ul>
          <li>
            Since there is no terminal in encounters, your terminal stat instead
            reduces enemy Max HP and ATK, with a maximum of 90% reduction at a
            TRM stat of 1,600.
          </li>
          <li>
            <strong>Tip:</strong> If you struggle with a group of enemies, you
            can run around them and wait for your skill cooldowns to recharge.
          </li>
        </ul>
        <h5>Reward Nodes</h5>
        <p>
          As you progress through Nexus, you will encounter reward nodes of
          various types. These will provide you a selection of bonus stats,
          skill points, or Trace skill levels to choose between.
        </p>
        <h5>Memory Trace Interactions with Reward Nodes</h5>
        <p>
          <strong>Illumination:</strong> Memory Traces have a chance of becoming
          illuminated every time you gain stats.
        </p>
        <ul>
          <li>
            The chance of a Trace becoming illuminated and the bonus stats
            gained during illumination increased with Trace level.
          </li>
          <li>
            If a Trace is not illuminated, the chance of illumination increases
            with each reward node.
          </li>
          <li>Illuminated Traces will remain illuminated until picked.</li>
          <li>
            You can view illuminated Traces and the chances of a Trace becoming
            illuminated on the top right of the screen during stat selection.
          </li>
        </ul>
        <StaticImage
          src="../../../images/ash/generic/nexus_8.webp"
          alt="Guides"
        />
        <ul>
          <li>
            The bonus stats conferred by illumination can be increased via the
            Memory Enhancement reward node.
          </li>
        </ul>
        <StaticImage
          src="../../../images/ash/generic/nexus_9.webp"
          alt="Guides"
        />
        <p>
          <strong>Memory Trace Properties</strong>:Each Memory Trace has a
          unique bonus that will be applied when certain conditions are met.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_10.webp"
          alt="Guides"
        />
        <ul>
          <li>
            Memory Traces have a Trace Awakening skill. This skill can be
            upgraded up to 5 times during a Nexus run. Levels 1, 3 and 5 will
            provide stronger versions of the skill while levels 2 and 4 provide
            a combination of skill points and stats.
          </li>
          <ul>
            <li>
              If a skill is already at max level, instead you will be given a
              large sum of skill points.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/ash/generic/nexus_11.webp"
          alt="Guides"
        />
        <ul>
          <li>
            Every Memory Trace has an array of skills that can be unlocked at
            random in certain reward nodes.
          </li>
        </ul>
        <StaticImage
          src="../../../images/ash/generic/nexus_12.webp"
          alt="Guides"
        />
        <h5>Danger Zones</h5>
        <p>
          Danger Zones act like standard stages: you can bring up to 7
          characters and an assistant character to defeat waves of enemies
          and/or a boss. Clearing Danger Zones for the first time rewards
          X-Particles. When encountering the same Danger Zone on subsequent
          runs, you will be able to skip the combat if you are strong enough or
          the depth level is 8 or lower (excluding the final boss node). Either
          way, you will receive multiple reward nodes.
        </p>
        <p>
          If you lose a battle in a Danger Zone, you will be given the option to
          either retry the battle or avoid it. Avoiding a Danger Zone will
          reduce your depth level and thus reduce your rewards.
        </p>
        <h5>Nexus Paths</h5>
        <p>
          Each Echoing Nexus has one or more Paths associated with it. After
          reaching a branch node during a run, you can select an unlocked path
          to continue the run through. Each Path consists of different enemies,
          mechanics, and Danger Zones. Each path can also reward an exclusive
          skill.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_13.webp"
          alt="Guides"
        />
        <h5>Finishing a Run</h5>
        <p>
          After finishing the final boss Danger Zone, you will collect your
          final reward nodes and then be able to select skills collected
          throughout the run to be used for your leader’s Engraving. Skills can
          be leveled up using skill points depending on the amount of times you
          upgraded them throughout the Nexus run.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_14.webp"
          alt="Guides"
        />

        <p>
          After you’ve selected and confirmed your skills, your Engraved skills
          and stats will become usable everywhere!
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_15.webp"
          alt="Guides"
        />
        <h5>Other Nexus Mechanics</h5>
        <p>
          <strong>Mental Load</strong>
        </p>
        <p>
          As you perform actions in the Nexus, your mental load will increase.
          As mental load increases, increasingly severe debuffs will be applied.
          Mental load can be reduced at certain reward nodes.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_16.webp"
          alt="Guides"
        />
        <p>
          <strong>Defense Scaling</strong>
        </p>
        <p>
          As you progress to higher depth levels, the DEF stat requirement will
          increase. Failure to meet this stat requirement will lead to a damage
          taken debuff proportional to the deficit.
        </p>
        <p>
          <strong>Stat Caps</strong>
        </p>
        <p>
          Depending on the Nexus type, each stat has a maximum limit that can be
          reached. - For example, Nexus: Phenomena and Nexus: Mirage have stat
          limits of 1,280 per stat.
        </p>
        <p>After a stat reaches 1,000, future gains will be halved.</p>
        <SectionHeader title="Nexus Reputation" />
        <p>
          The highest Engraving ratings obtained on each character that have
          completed a run on a path will be recorded. These ratings will
          contribute to the reputation level for the corresponding path. As the
          Reputation level increases, you will be rewarded with currency to be
          used on Memory Trace banners. Additionally, higher reputation levels
          provide stat bonuses for Nexus Memory Challenges and make it easier to
          get stats and skill points in that path.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_17.webp"
          alt="Guides"
        />
        <h5>Nexus Memory Challenges</h5>
        <p>
          Each Path has corresponding Nexus Memory Challenges. These challenges
          utilize the mechanics of the Path in a single stage. Challenges are
          unlocked by completing a run with high enough Engraving ratings. Bonus
          stats will be provided during challenge stages based on your Path
          reputation. Completing Memory Challenges yields valuable rewards, such
          as X-Particles, Memory Trace Selectors, and a special badge.
        </p>
        <StaticImage
          src="../../../images/ash/generic/nexus_18.webp"
          alt="Guides"
        />
        <SectionHeader title="Nexus Strategy" />
        <p>
          Before delving into the Nexus, it's important to keep in mind your
          team's priorities. Some Memory Traces require a stat threshold in
          order to trigger their skills, and you should keep that in mind when
          allocating stats. If you really want to Min-Max, you can also
          calculate beforehand the total of skill points you'll need, but most
          of the time that won't be necessary.
        </p>
        <p>
          The Nexus is actually quite intuitive, and most of the time you can
          pick the stat or skill option that gives the highest numbers.
        </p>
        <p>
          As a rule of thumb, give preference to the Path of Stats (Right) until
          you start struggling with encounters, then switch to Path of Skills
          (Left).
        </p>
        <SectionHeader title="Videos" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="SmwIYuf6sm8" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="57KYaSZcGww" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesNexus;

export const Head: React.FC = () => (
  <Seo
    title="Echoing Nexus | Ash Echoes | Prydwen Institute"
    description="An overview of the Echoing Nexus mode available in Ash Echoes."
    game="ash"
  />
);
